import TonIcon from "../../../../../assets/modal/ton.svg";
import MainMenuButton from "../../MainMenuButton/MainMenuButton";
import { useTonConnectModal, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useEffect } from "react";

interface ITonConnectButton {
  mainModalClose: () => void;
}

export default function TonConnectButton({
  mainModalClose,
}: ITonConnectButton) {
  const { open, state } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (tonConnectUI.connected) {
      mainModalClose();
    }
  }, [tonConnectUI.connected]);

  return (
    <>
      <MainMenuButton src={TonIcon} title="TON" action={open} />
    </>
  );
}
