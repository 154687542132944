import { AppTitle } from "../AppTitle/AppTitle";
import EthButton from "../Modal/Buttons/AuthButtons/EthButtons/EthButton";
import MainAuthButton from "../Modal/Buttons/AuthButtons/MainAuthButton";
import TonDisconnectButton from "../Modal/Buttons/AuthButtons/TonButtons/TonDisconnectButton";
import SignOutButton from "../Modal/Buttons/AuthButtons/Web2Buttons/SignOutButton";
import MainMenu from "../Modal/Menu/MainMenu";
import { HeaderContainer } from "./HeaderContainer";
import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { useCallback, useState } from "react";
import { useAccount } from "wagmi";


export default function Header({ username, updateProfile }: any) {
  const [tonConnectUI] = useTonConnectUI()
  const { isConnected } = useAccount();
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const WalletInfo = () => {
    if (tonConnectUI.connected) {
      console.log('ton', tonConnectUI.connected)
      return <TonDisconnectButton />;
    } else if (username) {
      return (
        <SignOutButton username={username} updateProfile={updateProfile} />
      );
    } else if (isConnected) {
      return <EthButton mainModalClose={() => console.log('close')} />;
    }
    return <MainAuthButton onClick={toggleOpen} />;
  };

  return (
    <HeaderContainer>
      <AppTitle />
      <div className="auth-buttons">
        <MainMenu
          isOpen={open}
          onClose={toggleOpen}
          username={username}
          updateProfile={updateProfile}
        />
        <WalletInfo />
      </div>
    </HeaderContainer>
  );
}
