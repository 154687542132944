import { HeaderButton } from "../../../../ui/Button";
import { DownOutlined } from "@ant-design/icons";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { Dropdown, Space } from "antd";
import React from "react";


export default function TonDisconnectButton() {
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const menu = {
    onClick: () => tonConnectUI.disconnect(),
    items: [
      {
        label: "Disconnect",
        key: "1",
      },
    ],
  };

  return (
    <Dropdown className="header-dropdown" menu={menu}>
      <HeaderButton>
        <Space color="#FDFFFD">
          {address.slice(0, 4) + "..." + address.slice(-3)}
          <DownOutlined />
        </Space>
      </HeaderButton>
    </Dropdown>
  );
}
